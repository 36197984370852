<template>
  <button type="button" :disabled="loading ? true : false" @click.stop="click"
    :class="[classes, 'text-' + fontSize, 'font-' + fontWeight]">
    <div v-if="!loading" class="flex flex-row w-full items-center justify-center">
      <slot></slot>
    </div>
    <div v-else>
      <SpinnersThreeDotsWhite :background="type==='primary' ? 'bg-white' : 'bg-gray-300'" />
    </div>
  </button>
</template>

<script setup>
const secondaryShared =
  "bg-white text-gray-700 shadow-sm hover:bg-gray-50 border-solid border border-gray-300";
const primaryShared =
  "bg-emerald-500 hover:bg-emerald-600 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500";
const deleteShared =
  "bg-white text-red-600 shadow-sm hover:bg-gray-50 border-solid border border-gray-300";
const sm = "rounded px-2 py-1";
const md = "rounded px-2 py-1";
const lg = "rounded-md px-4 py-2";
const xl = "rounded-md px-5 py-2.5";

const props = defineProps({
  size: {
    type: String,
    default: "md",
  },
  type: {
    type: String,
    default: "secondary",
  },
  fontSize: {
    type: String,
    default: "sm",
  },
  fontWeight: {
    type: String,
    default: "medium",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);

const click = (e) => {
  emit("click", e);
};

const classes = computed(() => {
  if (props.type === "secondary") {
    switch (props.size) {
      case "sm":
        return `${secondaryShared} ${sm}`;
      case "md":
        return `${secondaryShared} ${md}`;
      case "lg":
        return `${secondaryShared} ${lg}`;
      case "xl":
        return `${secondaryShared} ${xl}`;
      case "2xl":
        return `${secondaryShared} ${_2xl}`;
      default:
        return `${secondaryShared} ${md}`;
    }
  } else if (props.type === "delete") {
    switch (props.size) {
      case "sm":
        return `${deleteShared} ${sm}`;
      case "md":
        return `${deleteShared} ${md}`;
      case "lg":
        return `${deleteShared} ${lg}`;
      case "xl":
        return `${deleteShared} ${xl}`;
      case "2xl":
        return `${deleteShared} ${_2xl}`;
      default:
        return `${deleteShared} ${md}`;
    }
  } else {
    switch (props.size) {
      case "sm":
        return `${primaryShared} ${sm}`;
      case "md":
        return `${primaryShared} ${md}`;
      case "lg":
        return `${primaryShared} ${lg}`;
      case "xl":
        return `${primaryShared} ${xl}`;
      case "2xl":
        return `${primaryShared} ${_2xl}`;
      default:
        return `${primaryShared} ${md}`;
    }
  }
});
</script>
